<!--综合测评选项-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评选项</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 70%">
        <el-row :gutter="20">
          <el-col
            :span="6"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">类别:</span>
            <el-select
              v-model="lb"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                label="德育素质"
                :value="1"
              ></el-option>
              <el-option
                label="智育素质"
                :value="2"
              ></el-option>
              <el-option
                label="体育素质"
                :value="3"
              ></el-option>
              <el-option
                label="美育素质"
                :value="4"
              ></el-option>
              <el-option
                label="劳动素质"
                :value="5"
              ></el-option>
              <el-option
                label="能力素质"
                :value="6"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="6"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">类型:</span>
            <el-select
              v-model="lx"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                label="加分要素"
                :value="1"
              ></el-option>
              <el-option
                label="减分要素"
                :value="2"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">评分内容:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入评分内容"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-add"
          @click="showAdd"
        >添加</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="detailInfo"
        >编辑</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="deleteinfo"
        >删除</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_zx')"
          icon="el-icon-edit"
          @click="showChildDia"
        >编辑子项</el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="lb"
          label="类别"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lb === 1">德育素质</el-tag>
            <el-tag v-if="scope.row.lb === 2">智育素质</el-tag>
            <el-tag v-if="scope.row.lb === 3">体育素质</el-tag>
            <el-tag v-if="scope.row.lb === 4">美育素质</el-tag>
            <el-tag v-if="scope.row.lb === 5">劳动素质</el-tag>
            <el-tag v-if="scope.row.lb === 6">能力素质</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="lx"
          label="类型"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lx === 1">加分要素</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.lx === 2"
            >减分要素</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="pfnr"
          label="评分内容及标准"
        > </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="综合素质测评项目"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar
        style="height: 350px"
        class="myscrollbar"
      >
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-form-item
            label="类别"
            label-width="150px"
            prop="LB"
          >
            <el-select
              v-model="forminfo.LB"
              clearable
            >
              <el-option
                label="德育素质"
                :value="1"
              ></el-option>
              <el-option
                label="智育素质"
                :value="2"
              ></el-option>
              <el-option
                label="体育素质"
                :value="3"
              ></el-option>
              <el-option
                label="美育素质"
                :value="4"
              ></el-option>
              <el-option
                label="劳动素质"
                :value="5"
              ></el-option>
              <el-option
                label="能力素质"
                :value="6"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="评分内容及标准"
            label-width="150px"
            prop="LX"
          >
            <el-select
              v-model="forminfo.LX"
              clearable
            >
              <el-option
                label="加分要素"
                :value="1"
              ></el-option>
              <el-option
                label="减分要素"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="评分内容及标准"
            label-width="150px"
            prop="PFNR"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入内容"
              v-model="forminfo.PFNR"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="项目子项"
      :visible.sync="dialogXMZXVisible"
      width="50%"
      top="5vh"
    >
      <el-scrollbar
        style="height: 550px"
        class="myscrollbar"
      >
        <el-button
          type="primary"
          @click="showChildDiaForm"
          style="margin-left:20px;"
        >添加子项</el-button>
        <div class="c_table">
          <el-table
            :data="childList"
            :height="400"
            border
            style="width: 100%"
            header-cell-class-name="tableStyle"
            highlight-current-row
            :row-style="{ height: '20px' }"
            :cell-style="{ padding: '5px' }"
          >
            <el-table-column
              prop="sblb"
              label="申报类别"
              width="140"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.sblb === 0">个人申报</el-tag>
                <el-tag
                  v-if="scope.row.sblb === 1"
                  type="danger"
                >辅导员审核</el-tag>
                <el-tag
                  v-if="scope.row.sblb === 2"
                  type="success"
                >班级测评小组评议</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="sm"
              label="说明"
            >
            </el-table-column>
            <el-table-column
              width="100"
              prop="fs"
              label="分数"
            > </el-table-column>
            <el-table-column
              prop="id"
              label="操作"
              width="100"
            >
              <template slot-scope="scope">
                <el-button
                  @click="editChildItem(scope.row.id)"
                  type="text"
                  size="small"
                >编辑</el-button>
                <el-button
                  @click="delChildItem(scope.row.id)"
                  type="text"
                  size="small"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
      <el-dialog
        title="综合素质测评项目子项"
        :visible.sync="dialogChildItemVisible"
        width="50%"
        :close-on-click-modal="false"
        @close="closeChildDialog"
        top="5vh"
        append-to-body
      >
        <el-scrollbar
          style="height: 350px"
          class="myscrollbar"
        >
          <el-form
            :model="childForm"
            v-if="dialogChildItemVisible"
            ref="formChildRef"
            :rules="childFormRules"
          >
            <el-form-item
              label="申报类别"
              label-width="150px"
              prop="SBLB"
            >
              <el-select
                v-model="childForm.SBLB"
                clearable
              >
                <el-option
                  label="个人申报"
                  :value="0"
                ></el-option>
                <el-option
                  label="辅导员核定"
                  :value="1"
                ></el-option>
                <el-option
                  label="班级测评小组评议"
                  :value="2"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="分数"
              label-width="150px"
              prop="FS"
            >
              <el-input
                type="number"
                placeholder="请输入分数"
                v-model="childForm.FS"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="评分内容及标准"
              label-width="150px"
              prop="SM"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="请输入内容"
                v-model="childForm.SM"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="dialogChildItemVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveChildForm"
          >确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetZHCPXMPageList,
  getZHCPXMFormData,
  saveZHCPXM,
  getChildList,
  saveZHCPXMChild,
  getChildFormData,
  deleteChild,
} from '../../api/zhcp'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 50, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      lx: '',
      lb: '',
      forminfo: {
        ID: '',
        LB: '',
        LX: '',
        PFNR: '',
      },
      spzt: null,
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        LB: [
          {
            required: true,
            message: '请选择类别',
            trigger: 'blur',
          },
        ],
        LX: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        PFNR: [
          { required: true, message: '请填写评分内容及标准', trigger: 'blur' },
        ],
      },
      // 项目子项
      dialogXMZXVisible: false,
      childList: [],
      dialogChildItemVisible: false,
      childForm: {
        ID: '',
        XID: '',
        SM: '',
        FS: '',
        SBLB: '',
      },
      // 表单验证规则对象
      childFormRules: {
        SBLB: [
          {
            required: true,
            message: '请选择类别',
            trigger: 'blur',
          },
        ],
        FS: [{ required: true, message: '请填写分数', trigger: 'blur' }],
        SM: [
          { required: true, message: '请填写评分内容及标准', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(
          JSON.stringify({ ID: '', LB: '', LX: '', PFNR: '' })
        )
      })
      this.$refs.formRef.resetFields()
    },
    // 当添加/编辑弹出框关闭时调用
    closeChildDialog() {
      this.$nextTick(() => {
        this.childForm = JSON.parse(
          JSON.stringify({ ID: '', XID: '', SM: '', FS: '', SBLB: '' })
        )
      })
      this.$refs.formChildRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetZHCPXMPageList({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: this.lx,
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    showAdd() {
      this.dialogFormVisible = true
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getZHCPXMFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.LB = res.data.lb
            this.forminfo.LX = res.data.lx
            this.forminfo.PFNR = res.data.pfnr
            this.dialogFormVisible = true
          }
        })
      }
    },
    deleteinfo() {},
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveZHCPXM(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！')
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 项目子项弹框
    showChildDia() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getChildList({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.childList = res.data
            this.dialogXMZXVisible = true
          }
        })
      }
    },
    // 项目子项编辑弹框
    showChildDiaForm() {
      this.dialogChildItemVisible = true
    },
    // 保存子项
    saveChildForm() {
      this.$refs.formChildRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.childForm.XID = this.keyValue
          saveZHCPXMChild(this.childForm).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！')
              getChildList({ id: this.keyValue }).then((res) => {
                if (res.code === 200) {
                  this.childList = res.data
                }
              })
              this.dialogChildItemVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 编辑子项
    editChildItem(id) {
      getChildFormData({ id: id }).then((res) => {
        if (res.code === 200) {
          this.childForm.ID = res.data.id
          this.childForm.XID = res.data.xid
          this.childForm.SM = res.data.sm
          this.childForm.FS = res.data.fs
          this.childForm.SBLB = res.data.sblb
          this.dialogChildItemVisible = true
        }
      })
    },
    // 删除子项
    delChildItem(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          deleteChild({ id: id }).then((res) => {
            if (res.code === 200) {
              this.$message.success('删除成功！')
              getChildList({ id: this.keyValue }).then((res) => {
                if (res.code === 200) {
                  this.childList = res.data
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
